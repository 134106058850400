import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link, useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import { StaticImage } from 'gatsby-plugin-image'
import Headroom from 'react-headroom'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Logo from '../images/svg/logo-chanaka.svg'

const Header = ({ siteTitle, pageContext }) => {
  const [open, setOpen] = useState(true)
  const { language, defaultLanguage, languages, originalPath } = useI18next()
  const { t } = useTranslation()

  const clickHandler = () => {
    setOpen(!open)
    const body = document.getElementsByTagName('body')[0]
    body.classList.toggle('menu-is-open')
  }

  const handleScrollToSection = (e) => {
    setOpen(!open)
    const body = document.getElementsByTagName('body')[0]
    body.classList.remove('menu-is-open')
    console.log('handleScroll')
  }

  const lang = language === defaultLanguage ? '' : `${language}/`

  return (
    <div>
      <Headroom>
        <header className='bg-white px-4 py-3 shadow-section'>
          <div className='w-full'>
            <div className='w-full max-w-screen-xl mx-auto pr-20 relative'>
              <div className='flex justify-between items-center'>
                <Link
                  to="/"
                  style={{
                    color: `white`,
                    textDecoration: `none`,
                    display: `flex`
                  }}
                >
                  <Logo />
                </Link>
                <div className='ua-solidarity'>
                  <StaticImage
                          src="../images/ua-solidarity.png"
                          placeholder='none'
                          width={42}
                          quality={19}
                          formats={["AUTO", "WEBP", "AVIF"]}                          
                  />
                </div>
                <div className='flex flex-col md:flex-row md:items-center m-0 hidden'>
                  <div className='flex items-center'>
                    <div
                      className='hidden lg:block mr-1 font-medium text-sm'
                    >
                      {t('headerHeadline')}
                    </div>
                    <div className='hidden lg:block'>
                      <AnchorLink to={`/${lang}#pdGroupSection`}>
                        <StaticImage
                          src="../images/pd-group.png"
                          placeholder='none'
                          width={94}
                          quality={95}
                          formats={["AUTO", "WEBP", "AVIF"]}
                          alt={t('pdGroupAlt')}
                        />
                      </AnchorLink>

                    </div>
                  </div>
                  {/* <ul className='hidden lg:flex items-center  font-medium text-xs text-black md:ml-8'>
                    {languages.map((lng) => (
                      <li className='px-1 border-r border-current last:border-0' key={lng}>
                        <Link to={originalPath} language={lng}>
                          {lng}
                        </Link>
                      </li>
                    ))}
                  </ul> */}
                </div>
              </div>
              <div className='absolute top-1/2 right-2 transform -translate-y-1/2 z-100'>
                <button
                  aria-label='menu'
                  className={'flex flex-col items-center justify-center w-10 h-10 text-primary relative outline-none focus:outline-none nav-toggle' + (open ? '' : ' is-open')}
                  onClick={clickHandler}
                >
                  <span aria-hidden='true' />
                  <span aria-hidden='true' />
                  <span aria-hidden='true' />
                </button>
              </div>
            </div>
          </div>
        </header>
      </Headroom>
      <div className={`bg-primary h-screen fixed top-0 z-50 navbar-menu ${open ? '' : 'is-open'}`}>
        <div className='w-full h-screen max-w-screen-xl relative px-4 py-12 md:py-12 overflow-y-auto'>
          <div className='h-full'>
            <div className='px-4 w-full'>
              <ul className="w-full lg:ml-10 xl:ml-24 menu-list">
                <li className="relative">
                  <AnchorLink
                    to={`/${lang}firma`}
                    className='font-semibold text-white uppercase block py-1 md:py-2 xl:py-4'
                  >
                    <span className='block py-3 focus:outline-none' role='link' tabIndex={0} onClick={(e) => handleScrollToSection(e)} onKeyDown={(e) => handleScrollToSection(e)}>
                      {t('navTitles.company')}
                    </span>
                  </AnchorLink>
                  <span className='block border border-secondary w-full shadow-lg'></span>
                </li>

                <li className="relative">
                  <AnchorLink
                    to={`/${lang}pd-refractories`}
                    className='font-semibold text-white uppercase block py-1 md:py-2 xl:py-4'
                  >
                    <span className='block py-3 focus:outline-none' role='link' tabIndex={0} onClick={(e) => handleScrollToSection(e)} onKeyDown={(e) => handleScrollToSection(e)}>
                      {t('navTitles.pdGroup')}
                    </span>
                  </AnchorLink>
                  <span className='block border border-secondary w-full shadow-lg'></span>
                </li>

                <li className="relative">
                  <AnchorLink
                    to={`/${lang}produkty`}
                    className='font-semibold text-white uppercase block py-1 md:py-2 xl:py-4'
                  >
                    <span className='block py-3 focus:outline-none' role='link' tabIndex={0} onClick={(e) => handleScrollToSection(e)} onKeyDown={(e) => handleScrollToSection(e)}>
                      {t('navTitles.products')}
                    </span>
                  </AnchorLink>
                  <span className='block border border-secondary w-full shadow-lg'></span>
                  </li>
                  



                  {/* 
                  
                  <ul className='w-full m-0 py-4'>
                    <li className='flex items-center'>
                      <span className='hidden md:block border border-secondary w-6 mr-2 shadow-md'></span>
                      <AnchorLink
                        to={`/${lang}produkty`}
                        className='text-white py-2'
                      >
                        <span className='focus:outline-none' role='link' tabIndex={0} onClick={(e) => handleScrollToSection(e)} onKeyDown={(e) => handleScrollToSection(e)}>
                          {t('navTitles.productsCatalogue')}
                        </span>
                      </AnchorLink>
                    </li>
                    <li className='flex items-center'>
                      <span className='hidden md:block border border-secondary w-6 mr-2 shadow-md'></span>
                      <AnchorLink
                        to={`/${lang}dokumentacja`}
                        className='text-white py-2'
                      >
                        <span className='focus:outline-none' role='link' tabIndex={0} onClick={(e) => handleScrollToSection(e)} onKeyDown={(e) => handleScrollToSection(e)}>
                          {t('navTitles.technicalDocumentation')}
                        </span>
                      </AnchorLink>
                    </li>
                    <li className='flex items-center'>
                      <span className='hidden md:block border border-secondary w-6 mr-2 shadow-md'></span>
                      <AnchorLink
                        to={`/${lang}dokumentacja#technicalDrawings`}
                        className='text-white py-2'
                      >
                        <span className='focus:outline-none' role='link' tabIndex={0} onClick={(e) => handleScrollToSection(e)} onKeyDown={(e) => handleScrollToSection(e)}>
                          {t('navTitles.technicalDrawings')}
                        </span>
                      </AnchorLink>
                    </li>
                  </ul>
                */}

        
                <li className="relative">
                  <AnchorLink
                    to={`/${lang}dokumentacja`}
                    className='font-semibold text-white uppercase block py-1 md:py-2 xl:py-4'
                  >
                    <span className='block py-3 focus:outline-none' role='link' tabIndex={0} onClick={(e) => handleScrollToSection(e)} onKeyDown={(e) => handleScrollToSection(e)}>
                      {t('navTitles.technicalDocumentation')}
                    </span>
                  </AnchorLink>
                  <span className='block border border-secondary w-full shadow-lg'></span>
                </li>

                <li className="relative">
                  <AnchorLink
                    to={`/${lang}#contactSection`}
                    className='font-semibold text-white uppercase block py-1 md:py-2 xl:py-4'
                  >
                    <span className='block py-3 focus:outline-none' role='link' tabIndex={0} onClick={(e) => handleScrollToSection(e)} onKeyDown={(e) => handleScrollToSection(e)}>
                      {t('navTitles.contact')}
                    </span>
                  </AnchorLink>
                  <span className='block border border-secondary w-full shadow-lg'></span>
                </li>

                <li className="relative">
                  <AnchorLink
                    to={`/${lang}polityka-prywatnosci`}
                    className='font-semibold text-white uppercase block py-1 md:py-2 xl:py-4'
                  >
                    <span className='block py-3 focus:outline-none' role='link' tabIndex={0} onClick={(e) => handleScrollToSection(e)} onKeyDown={(e) => handleScrollToSection(e)}>
                      {t('navTitles.policyPrivacy')}
                    </span>
                  </AnchorLink>
                  
                </li>

                {/* 
                <li className="relative">
                  <AnchorLink
                    to={`/${lang}dokumentacja#technicalDrawings`}
                    className='font-semibold text-white uppercase block py-1 md:py-2 xl:py-4'
                  >
                    <span className='block py-3 focus:outline-none' role='link' tabIndex={0} onClick={(e) => handleScrollToSection(e)} onKeyDown={(e) => handleScrollToSection(e)}>
                      {t('navTitles.technicalDrawings')}
                    </span>
                  </AnchorLink>
                  <span className='block border border-secondary w-full shadow-lg'></span>
                </li>
                 */}



              </ul>
            </div>

            {/* <div className='lg:hidden w-3/12 '>
            <ul className='lg:hidden flex flex-col items-center  font-medium text-xs text-black md:ml-8'>
              {languages.map((lng) => (
                <li className='text-secondary' key={lng}>
                  <Link to={originalPath} language={lng} className='inline-block font-semibold my-2 p-3 uppercase'>
                    {lng}
                  </Link>
                </li>
              ))}
            </ul>
          </div> */}


          </div>
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

