import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link, useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Scroll from "../../molecules/Scroll"

const Footer = () => {
  const { t } = useTranslation()
  const { language, defaultLanguage, languages, originalPath } = useI18next()
  const lang = language === defaultLanguage ? '' : `${language}/`
  return (
    <footer className=''>
      <div className='px-4 py-3 bg-secondary'>
        <div className='w-full max-w-screen-xl mx-auto'>
          <ul className='flex flex-wrap items-center md:justify-between'>
            <li className='py-1 md:py-0 w-1/3 md:w-auto flex-grow leading-none'>
              <AnchorLink 
                to={`/${lang}firma`}
                className='text-gray text-xs md:text-lg'
              >
                <span>{t('navTitles.company')}</span>
              </AnchorLink>
            </li>
            <li className='py-1 md:py-0 w-1/3 md:w-auto flex-grow leading-none'>
              <AnchorLink 
                to={`/${lang}produkty`}
                className='text-gray text-xs md:text-lg'
              >
                <span>{t('navTitles.products')}</span>
              </AnchorLink>
            </li>
            <li className='py-1 md:py-0 w-1/3 md:w-auto flex-grow leading-none'>
              <AnchorLink 
                to={`/${lang}#expirienceSection`}
                className='text-gray text-xs md:text-lg'
              >
                <span>{t('navTitles.expirience')}</span>
              </AnchorLink>
            </li>
            
            <li className='py-1 md:py-0 w-1/3 md:w-auto flex-grow leading-none'>
              <AnchorLink 
                to={`/${lang}#strategySection`}
                className='text-gray text-xs md:text-lg'
              >
                <span>{t('navTitles.strategy')}</span>
              </AnchorLink>
            </li>
            <li className='py-1 md:py-0 w-1/3 md:w-auto flex-grow leading-none'>
              <AnchorLink 
                to={`/${lang}pd-refractories`}
                className='text-gray text-xs md:text-lg'
              >
                <span>{t('navTitles.pdGroup')}</span>
              </AnchorLink>
            </li>
            
            <li className='py-1 md:py-0 w-1/3 md:w-auto flex-grow leading-none'>
              <AnchorLink 
                to={`/${lang}dokumentacja`}
                className='text-gray text-xs md:text-lg'
              >
                <span>{t('navTitles.technicalDocumentation')}</span>
              </AnchorLink>
            </li>
{/*
            <li className='py-1 md:py-0 w-1/3 md:w-auto flex-grow leading-none'>
              <AnchorLink 
                to={`/${lang}#contactSection`}
                className='text-gray text-xs md:text-lg'
              >
                <span>{t('navTitles.contact')}</span>
              </AnchorLink>
            </li>
 */}
          </ul>
        </div>
      </div>
      <div className='px-4 py-8 bg-primary'>
        <div className='w-full max-w-screen-xl mx-auto'>
          <div className='flex flex-wrap items-center -mx-4'>
            <div className='w-full md:w-3/12 px-4 hidden'>
              <div className='flex flex-wrap items-center'>
                <div className='mr-4 md:mr-6 xl:mr-16 py-3 lg:py-0'>
                  <StaticImage
                    src="../../../images/products-pd-group.png"
                    width={96}
                    quality={95}
                    placeholder=''
                    alt={t('pdGroupAlt')}
                  />
                </div>
                <div className='py-3 lg:py-0'>
                  <StaticImage
                    src="../../../images/products-pd-refractories.png"
                    width={113}
                    quality={95}
                    placeholder=''
                    alt={t('pdGroupAlt')}
                  />
                </div>
              </div>
            </div>
            <div className='w-full md:w-9/12 px-4'>
              <div className='text-white text-xs md:text-sm mt-2 md:mt-0'>
                  {t('footer.pdGroupNote')}
                </div>
            </div>
          </div>
        </div>
      </div>
      <div className='px-4 py-4'>
        <div className='w-full max-w-screen-xl mx-auto'>
          <div className='flex flex-wrap items-center justify-between -mx-2'>
            <div className='w-3/4 px-2'>
              <div className='text-xs md:text-sm'>
                © {new Date().getFullYear()} {t('footer.copyrights')}
                {` `}
                {//<Link to={`/polityka-prywatnosci`}>{t('navTitles.policyPrivacy')}</Link>
                }
              </div>
            </div>
            {/* <div className='w-1/2 px-2'>
              <ul className='flex items-center justify-end font-medium text-xs text-black md:ml-8'>
                {languages.map((lng) => (
                  <li className='uppercase px-1 border-r border-current last:border-0' key={lng}>
                    <Link to={originalPath} language={lng}>
                      {lng}
                    </Link>
                  </li>
                ))}
              </ul>
            </div> */}
          </div>
        </div>
      </div>
      <Scroll className='w-full h-full flex justify-center items-center' showBelow={600} />
    </footer>
  )
}

export default Footer
