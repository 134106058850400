import React from 'react'
import CookieConsent, { Cookies } from 'react-cookie-consent'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const CookiesBanner = () => {
  const { t } = useTranslation()
  return (
    <CookieConsent
      location='bottom'
      declineButtonText='Decline'
      containerClasses='coookie-consent-container'
      buttonClasses='coookie-consent-button'
      contentClasses='coookie-consent-content'
      buttonWrapperClasses='coookie-consent-button-wrapper'
      buttonText='OK'
      disableStyles
      onAccept={() => {
        Cookies.set('gatsby-gdpr-google-analytics', 'true')
        Cookies.set('gatsby-gdpr-google-tagmanager', 'true')
      }}
    >
      <div className='max-w-screen-lg md:pr-4 mb-6 md:mb-0'>
        {t('cookiesBanner')}
      </div>
    </CookieConsent>
  )
}

export default CookiesBanner
